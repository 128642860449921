<template>
    <div>
        <b-overlay :show="!pageLoaded">
            <b-card class="practice-edit-wrapper">
                <p>This is the PRACTICE PROFILE where details are entered which describe the Practice, including its business structure, the number of owners, partners, employees and subcontractors involved.</p>
                <p>It also covers services offered and how the Practice manages things like engagement with its clients, compliance with anti-money laundering legislation as well as oversight of its own business processes and staff training.</p>
                <p>Certain questions will generate further profiles, for example in relation to each staff member. Uploading of documents may also be invited, for example the Practice anti-money laundering policy.</p>
                <p>Make sure your entries are accurate as all the questions are relevant. You should ‘save’ your work as you go through the questions. If necessary, you can return to where you left off to continue your entries.</p>
                <p>AML Complete will calculate ‘risk’ issues and ‘compliance’ as you proceed. Once the data is ‘saved’ the new results are displayed on the ‘dashboard’ page.</p>
                <p>AML Complete is designed to highlight elevated risk or ‘non-compliance’ so that you have the opportunity to address any gaps. Where something is updated (for example new staff training occurs) entries may be revised and the system will re-calculate.</p>
                <b-form class="mt-2">
                    <b-row>
                        <b-col md="12">
                            <h3>General Information</h3>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Practice Name" label-for="practice-name" class="mb-2">
                                <b-form-input id="practice-name" v-model="practice.name" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Practice Owner / IAB Member Account Holder Name" label-for="account-holder-name" class="mb-2">
                                <b-form-input id="account-holder-name" v-model="practice.accountHolderName" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Practice Email as registered with IAB" label-for="practice-email" class="mb-2">
                                <b-form-input id="practice-email" v-model="practice.email" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Practice Telephone Number" label-for="practice-phone">
                                <b-input-group>
                                    <b-input-group-prepend is-text>UK (+44)</b-input-group-prepend>
                                    <b-form-input id="practice-phone" v-model="practice.phone" autocomplete="off" disabled/>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Practice Structure" label-for="practice-structure" class="mb-2">
                                <v-select id="practice-structure" v-model="practice.structure" :options="structureTypes" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="practice.structure && (practice.structure !== 'Sole Trader' && practice.structure !== 'Partnership')">
                            <b-form-group label="What is the Registration Number of the Limited Company/Partnership?" label-for="practice-registration-number" class="mb-2">
                                <b-form-input id="practice-registration-number" v-model="practice.registrationNumber" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <h3>Principal Business Address</h3>
                            <b-form-group label="Street Address" label-for="practice-street-address" class="mb-2">
                                <b-form-input id="principle-street-address" v-model="practice.principleStreetAddress" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="City" label-for="principle-city" class="mb-2">
                                <b-form-input id="principle-city" v-model="practice.principleCity" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Postal Code" label-for="principle-postal-code" class="mb-2">
                                <b-form-input id="principle-postal-code" v-model="practice.principlePostalCode" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <h3>Business Registered Address</h3>
                            <b-form-group class="mb-2">
                                <b-form-checkbox v-model="businessAddressSame" id="business-address-same" disabled>Same as Principal Business Address</b-form-checkbox>
                            </b-form-group>
                            <b-form-group v-if="!businessAddressSame" label="Street Address" label-for="registered-street-address" class="mb-2">
                                <b-form-input id="registered-street-address" v-model="practice.registeredStreetAddress" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group v-if="!businessAddressSame" label="City" label-for="registered-city" class="mb-2">
                                <b-form-input id="registered-city" v-model="practice.registeredCity" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group v-if="!businessAddressSame" label="Postal Code" label-for="registered-postal-code" class="mb-2">
                                <b-form-input id="registered-postal-code" v-model="practice.registeredPostalCode" autocomplete="off" disabled/>
                            </b-form-group>
                        </b-col>
                      <b-col md="12">
                        <h3>Totals</h3>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Total Beneficial Owners" label-for="total-beneficial-owners" class="mb-2">
                          <b-form-input type="number" step="1" id="total-beneficial-owners" v-model="practice.totalBeneficialOwners" autocomplete="off"/>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Total Staff" label-for="total-staff" class="mb-2">
                          <b-form-input type="number" id="total-staff" v-model="practice.totalStaff" autocomplete="off"/>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Total Subcontractors" label-for="total-subcontractors" class="mb-2">
                          <b-form-input type="number" id="total-subcontractors" v-model="practice.totalSubcontractors" autocomplete="off"/>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Total Clients" label-for="total-clients" class="mb-2">
                          <b-form-input type="number" id="total-clients" v-model="practice.totalClients" autocomplete="off"/>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <risk-question-block :system-form-id="1" :form="form" :answers="practice.answers" v-on:updateProgressReturn="updateProgressListener" v-on:updateRiskReturn="updateRiskListener" v-on:getAnswersReturn="getAnswersListener" v-on:updateFilesReturn="updateFilesListener"></risk-question-block>
                    <b-row>
                        <b-col cols="8" class="mt-50">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" @click="savePractice(false)" :disabled="savingPractice">
                                <span v-if="!savingPractice">Save Changes</span>
                                <span v-if="savingPractice"><b-spinner small /> Saving...</span>
                            </b-button>
                            <b-button :disabled="!canComplete || completed || savingPractice" v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="!completed ? 'success' : 'flat-success'" class="mr-1" @click="savePractice(true)">
                                <span class="align-middle" v-if="!completed && !savingPractice">Complete</span>
                                <span class="align-middle" v-if="completed &&  !savingPractice"><feather-icon icon="CheckIcon"/> Completed</span>
                                <span v-if="savingPractice"><b-spinner small /> Saving...</span>
                            </b-button>
                        </b-col>
                        <b-col cols="4" class="mt-50 text-right">
                            <sup>Last updated: {{ practice.lastUpdated }}</sup>
                        </b-col>
                    </b-row>
                </b-form>
                <br><br>
                <h3>Practice Risk: {{ practiceRisk }}</h3>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>
    import {BCard,BForm,BRow,BCol,BFormGroup,BFormInput,BButton,BInputGroupPrepend,BInputGroup,BFormCheckbox,BSpinner,BOverlay} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import Ripple from 'vue-ripple-directive'
    import Cleave from 'vue-cleave-component'
    import 'cleave.js/dist/addons/cleave-phone.gb'
    import practiceService from '@core/services/practice/usePractice'
    import RiskQuestionBlock from "../../blocks/RiskQuestionBlock";
    import {$themeConfig} from "@themeConfig";
    export default {
        components: {
            BCard,
            BForm,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BInputGroupPrepend,
            BFormCheckbox,
            BSpinner,
            BOverlay,
            RiskQuestionBlock,
            vSelect,
            Cleave
        },
        directives: {
            Ripple
        },
        data(){
            return {
                practice: {
                    id: 0,
                    totalBeneficialOwners: 0,
                    totalStaff: 0,
                    totalSubcontractors: 0,
                    totalClients: 0,
                    name: "",
                    accountHolderName: "",
                    structure: "",
                    registrationNumber: "",
                    email: "",
                    phone: "",
                    principleStreetAddress: "",
                    principleCity: "",
                    principlePostalCode: "",
                    registeredStreetAddress: "",
                    registeredCity: "",
                    registeredPostalCode: "",
                    lastUpdated: "",
                    answers: []
                },
                form: {},
                files: [],
                structureTypes: ['Sole Trader','Partnership','Ltd Company','Limited Partnership','Limited Liability Partnership','Other'],
                businessAddressSame: true,
                canComplete: false,
                completed: false,
                practiceRisk: 0,
                pageLoaded: false,
                savingPractice: false
            }
        },
        methods: {
            getAnswersListener(value){
                this.riskQuestions = value;
                this.getPractice();
            },
            getPractice(){
                let userData = JSON.parse(localStorage.getItem('userData'));
                if(userData.practiceId){
                    practiceService.getPractice({id: userData.practiceId}).then(response => {
                        this.practice = response.data;
                        this.canComplete = this.practice.isComplete;
                        this.completed = this.practice.completed;
                        this.practiceRisk = this.practice.risk;
                        let that = this;
                        if(this.practice.answers.length > 0){
                            this.practice.answers.forEach(function(answer){
                                if(answer.questionType === 'checkbox'){
                                    that.$set(that.form, answer.questionLabel, answer.selections)
                                }else if(answer.questionType === 'multi_select'){
                                    let question = that.riskQuestions.find(({id}) => id === answer.questionId);
                                    let previousSelections = [];
                                    answer.selections.forEach(function(selection){
                                        question.answers.forEach(function(answer){
                                            if(answer.answer === selection){
                                                previousSelections.push(answer);
                                            }
                                        });
                                    });
                                    that.$set(that.form,answer.questionLabel,previousSelections)
                                }else{
                                    that.$set(that.form,answer.questionLabel,answer)
                                }
                            });
                        }
                        if ( this.practice.principleStreetAddress !== this.practice.registeredStreetAddress || this.practice.principleCity !== this.practice.registeredCity || this.practice.principlePostalCode !== this.practice.registeredPostalCode ){
                          this.businessAddressSame = false;
                        }
                        this.pageLoaded = true;
                    }).catch(error => {
                        if(error.response.status == 403){
                          window.location = $themeConfig.app.suspendedAccountRedirect;
                        }
                    });
                }else{
                    this.pageLoaded = true;
                }
            },
            savePracticeProgress(){
                let userData = JSON.parse(localStorage.getItem('userData'));
                let data = new FormData();
                data.append('practice',JSON.stringify(this.practice));
                if(userData.practiceId){
                    data.append('id',userData.practiceId);
                }
                data.append('complete','false');
                if(this.files.length > 0){
                    this.files.forEach(function(file){
                        data.append(file.name,file.file);
                    });
                }
                if(userData.practiceId){
                    practiceService.updatePractice(data).then(response => {
                        this.canComplete = response.data.complete;
                    }).catch(error => {
                        if(error.response.status == 403){
                            window.location = $themeConfig.app.suspendedAccountRedirect;
                        }
                    });
                }
            },
            savePractice(complete){
                this.savingPractice = true;
                let userData = JSON.parse(localStorage.getItem('userData'));
                let data = new FormData();
                data.append('practice',JSON.stringify(this.practice));
                if(userData.practiceId){
                    data.append('id',userData.practiceId);
                }
                data.append('complete',complete);
                if(this.files.length > 0){
                    this.files.forEach(function(file){
                        data.append(file.name,file.file);
                    });
                }
                if(userData.practiceId){
                    practiceService.updatePractice(data).then(response => {
                        if(response.data.errors){
                            this.showErrorMessage("Something went wrong");
                        }else{
                            this.showSuccessMessage("You have successfully updated your practice!");
                            if(complete && !this.completed){
                                this.completed = true;
                            }
                        }
                        this.savingPractice = false;
                    }).catch(error => {
                        if(error.response.status == 403){
                            window.location = $themeConfig.app.suspendedAccountRedirect;
                        }
                    });
                }
            },
            updateProgressListener(value){
                this.practice.answers = value;
                this.savePracticeProgress();
            },
            updateFilesListener(value){
                console.log("update files");
                this.files = value;
            },
            updateRiskListener(value) {
                this.practiceRisk = value;
            }
        }
    }
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>